import React from 'react'
import './Contato.css'
import emailjs from 'emailjs-com'

const Contato = (props) => {

    function sendEmail(e) {
        e.preventDefault()

        emailjs.sendForm('nunessite', 'template_1xdh8no', e.target, 'user_yGqTsnhXAKit6hXSIIaIa').then((result) => {
            alert('Recebemos sua mensagem, retornaremos o quanto antes! Obrigado!')
        },
        (error) => {
            alert(error.message)
        }
        )
        e.target.reset()
    }

    return(
        <section id="Contato" className="Contato">
            <div className="conteudo">
                
            </div>
            <div className="texto">
                <h1>Fale conosco!</h1>
                <p>Se você deseja obter mais informações sobre nossos serviços ou discutir outros detalhes, entre em contato conosco conforme meios informados.</p>
            </div>
            <div className="imgContato">
                <form onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="Nome" />
                    <input type="text" name="email" placeholder="Email" />
                    <input type="text" name="phone" placeholder="Telefone" />
                    <input type="text" name="subject" placeholder="Assunto" />
                    <textarea name="message" id="assunto" cols="30" rows="10" placeholder="Mensagem" />
                    <button type="submit" className="vermais"><i className="fas fa-paper-plane"></i> Enviar</button>
                </form>
            </div>
        </section>
    )
}

export default Contato