import React from 'react';
import './IconSocial.css'

const IconSocial = (props) => {
  
  return (
    <button className="IconSocial" onClick={props.click}>
        <i className={props.icon}></i>
    </button>
  )
}

export default IconSocial