import React from 'react'

const Sobre = (props) => {

    return(
        <div className="mvv">
            <div className="MVV missao">
                <div className="cardd c1">
                    <i className="fas fa-tasks"></i>
                </div>
                <div className="esquerda"></div>
                <div className="carddbody">
                    <h3>Missão</h3>
                    <p>Oferecer soluções em contabilidade com o comprometimento com a satisfação de nossos clientes, através do cumprimento de prazos e metas e a busca constante da excelência dos serviços prestados.</p>
                </div>
                <div className="direita"></div>
            </div>
            <div className="MVV visao">
                <div className="cardd c2">
                    <i className="fas fa-chart-bar"></i>
                </div>
                <div className="esquerda"></div>
                <div className="carddbody">
                    <h3>Visão</h3>
                    <p>Entregar aos seus clientes um trabalho idôneo e de alta qualidade, auxiliando na tomada de decisão e implantando boas práticas de gestão contribuindo para a prosperidade do seu negócio.</p>
                </div>
                <div className="direita"></div>
            </div>
            <div className="MVV valores">
                <div className="cardd c3">
                    <i className="fas fa-user-tie"></i>
                </div>
                <div className="esquerda"></div>
                <div className="carddbody">
                    <h3>Valores</h3>
                    <p>Respeito - integridade - Responsabilidade - União - Conhecimento - Reconhecimento e Oportunidade.</p>
                </div>
                <div className="direita"></div>
            </div>
        </div>
    )
}

export default Sobre