import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'

ReactDOM.render(
    <App />,
  document.getElementById('root')
);