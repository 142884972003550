import React from 'react'
import './Pessoas.css'

const Equipes = (props) => {

    return(
        <div id="Equipes" className="Equipes">
            <div className="titulo"><h1>Nossa CEO</h1></div>
            <div className="equipescard equipes1">
                <div className="imgequipes1"></div>
                <div className="equipesdesc">
                    <h4>Ana Nunes</h4>
                    <h5>CEO</h5>
                    <p>A vasta expertise no segmento, possibilitou ser a CEO da Nunes Contabilidade.</p>
                    <p>Sempre acreditando que o básico é o mais avançado que existe, sempre em processo de atualização, proporcionando aos seus clientes satisfação plena em seus serviços.</p>
                </div>
            </div>
        </div>
    )
}

export default Equipes