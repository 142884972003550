import React from 'react'

const Sobre = (props) => {

    return(
        <section id="Sobre" className="Sobre">
            
            <div className="sobre">
                <h1>Nossa História</h1>
                <div className="traco"></div>
                <p>Idealizando realizar um trabalho mais especializado levaram Ana Maria Nunes em 2003 a deixar a sociedade em um grande escritório de contabilidade e se dedicar a contabilidade da Construção Civil, se tornando referência no mercado nos dias atuais.</p>
                <p>Fundou a Nunes Soluções em Contabilidade, voltada principalmente para a construção civil, realizando um trabalho direcionado a empresas que buscam organização, gestão e apoio estratégico nesse setor.</p>
                <p>Ao longo dos anos, foi sendo reconhecida em Taubaté pela sua credibilidade e confiança na área de contabilidade. Atualmente seus clientes estão localizados no Vale do Paraíba, Fundo do Vale e Grande São Paulo.</p>
            </div>
            <div className="imgSobre"></div>
        </section>
    )
}

export default Sobre