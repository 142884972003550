import React from 'react'
import './Endo.css'

const Endo = (props) => {

    return(
        <section id="Endo" className="Endo">
            <div className="imgEndo"></div>
            <div className="conteudo">
                <h1>Endomarketing</h1>
                <p>O Endomarketing na Nunes Contabilidade iniciou-se em Janeiro/2020 onde a empresa percebeu a necessidade de enxergar seus colaboradores de uma forma mais humanizada.</p>
                <p>As campanhas ocorrem semanalmente, abordamos assuntos que estão presentes no dia a dia e convívio social dos nossos colaboradores como empatia, crenças e crenças limitantes, mudança de hábitos, os benefícios da meditação, procrastinação, entre outros.</p>
                <a href="#Ritual" className="vermais"><i className="fas fa-chevron-down"></i> Nosso Endomarketing</a>
            </div>
        </section>
    )
}

export default Endo