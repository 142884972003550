import React from 'react'

const Servicos = (props) => {

    return(
        <section id="Servicos" className="Servicos">
            <div className="imgServicos"></div>
            <div className="Servico">
                <h1>Podemos fazer<br />MUITO por você!</h1>
                <p>A Nunes contabilidade oferece aos seus clientes um trabalho de consultoria, verificando todas as diretrizes que refletem nas entregas finais da contabilidade.  Desta forma apresenta uma homogeneidade das informações, trazendo um reflexo mais transparente das informações e segurança para o empresário.</p>
                <a href="#SVC" className="vermais"><i className="fas fa-chevron-down"></i> Ver Mais</a>
            </div>
            
        </section>
    )
}

export default Servicos