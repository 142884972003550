import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './App.css'
import Logo from './imgs/logonunes.png'
import IconSocial from './components/IconSocial';

import Home from './pages/Home/Home'

import Sobre from './pages/Sobre/Sobre'
import MVV from './pages/Sobre/MVV'
import Categorias from './pages/Sobre/Categorias';

import Servicos from './pages/Servicos/Servicos'
import SVC from './pages/Servicos/Svc';

import Equipe from './pages/Equipe/Equipe';
import Pessoas from './pages/Equipe/Pessoas';

import Endo from './pages/Endo/Endo';
import Ritual from './pages/Endo/Ritual';

import Contato from './pages/Contato/Contato';

import Footer from './Footer'

const App = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [show, setShow] = useState(false)

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  function insta(){
    window.open('https://www.instagram.com/nunescontabilidade1/')
  }

  function face(){
      window.open('https://www.facebook.com/pages/Nunes%20Solu%C3%A7%C3%B5es%20em%20Contabilidade/454619944578543/')
  }

  function linkedin(){
      window.open('https://www.linkedin.com/in/ana-maria-nunes-spoljaric-74268842/')
  }

  function whats(){
      window.open('https://api.whatsapp.com/send?phone=5512997166077')
  }

  return (
    <div className="App">
      <div className="menu">
      <Nav className="Nav" tabs>
        <NavItem className="NavItem">
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Home
          </NavLink>
        </NavItem>

        <NavItem className="NavItem">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Sobre
          </NavLink>
        </NavItem>

        <NavItem className="NavItem">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Serviços
          </NavLink>
        </NavItem>

        <NavItem className="NavItem">
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Equipe
          </NavLink>
        </NavItem>

        <NavItem className="NavItem">
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
            Endomarketing
          </NavLink>
        </NavItem>

        <NavItem className="NavItem">
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            Contato
          </NavLink>
        </NavItem>

      </Nav>
      </div>
      <div className="menuCel">
        <button onClick={() => setShow(!show)}><i className="fas fa-list-ul"></i></button>
        {
          show === true ?
          <div className="openMenu">
              <button
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { 
                  toggle('1')
                  setShow(false)
                }}
              >
                Home
              </button>
              <button
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { 
                  toggle('2')
                  setShow(false)
                }}
              >
                Sobre
              </button>
              <button
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { 
                  toggle('3')
                  setShow(false)
                }}
              >
                Serviços
              </button>
              <button
                className={classnames({ active: activeTab === '4' })}
                onClick={() => { 
                  toggle('4')
                  setShow(false)
                }}
              >
                Equipe
              </button>
              <button
                className={classnames({ active: activeTab === '5' })}
                onClick={() => { 
                  toggle('5')
                  setShow(false)
                }}
              >
                Endomarketing
              </button>
              <button
                className={classnames({ active: activeTab === '6' })}
                onClick={() => { 
                  toggle('6')
                  setShow(false)
                }}
              >
                Contato
              </button>
          </div> : null
        }
      </div>
      <img src={Logo} alt="Logo" className={activeTab === '1' ? "Logo" : "LogoCel"} />
      <div className="icones">
        <IconSocial icon="fab fa-facebook-f" click={face} />
        <IconSocial icon="fab fa-instagram" click={insta} />
        <IconSocial icon="fab fa-linkedin-in" click={linkedin} />
        <IconSocial icon="fab fa-whatsapp" click={whats} />
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Home />
        </TabPane>

        <TabPane tabId="2">
          <Sobre />
          <MVV />
          <Categorias />
        </TabPane>

        <TabPane tabId="3">
          <Servicos />
          <SVC />
        </TabPane>

        <TabPane tabId="4">
          <Equipe />
          <Pessoas />
        </TabPane>

        <TabPane tabId="5">
          <Endo />
          <Ritual />
        </TabPane>

        <TabPane tabId="6">
          <Contato />
        </TabPane>

      </TabContent>
      <Footer />
    </div>
  );
}

export default App;