import React from 'react'

const Categorias = (props) => {

    return(
        <div id="Categorias" className="Categorias">
            <h1>O que esperar da Nunes?</h1>
            <div className="cat">
                <div className="imgCat cat1"></div>
                <h3>Foco no negócio</h3>
                <p>Uma contabilidade especializada e diretiva. Buscando sempre a otimização dos processos, verificando e corrigindo riscos do negócio.</p>
            </div>
            <div className="cat">
                <div className="imgCat cat2"></div>
                <h3>Uma equipe dedicada</h3>
                <p>Profissionais sempre dispostos a sanar todas as dúvidas e dar a diretrizes necessárias para tomadas de decisões estratégicas.</p>
            </div>
            <div className="cat">
                <div className="imgCat cat3"></div>
                <h3>Adicionando valores e recomendações</h3>
                <p>Nosso foco é realizar um trabalho descomplicado e contínuo, contribuindo de forma ativa na empresa.</p>
            </div>
        </div>
    )
}

export default Categorias