import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import './Toggle.css'

const Toggle = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="toggle">
      <button onClick={toggle}><i className={props.icone}></i><h3>{props.titulo}</h3></button>
      <Collapse isOpen={isOpen}>
            {props.children}
      </Collapse>
    </div>
  );
}

export default Toggle;