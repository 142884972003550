import React from 'react'
import Logo from './imgs/logonunes.png'
import IconSocial from './components/IconSocial'

const Footer = (props) => {
    function insta(){
        window.open('https://www.instagram.com/nunescontabilidade1/')
    }

    function face(){
        window.open('https://www.facebook.com/pages/Nunes%20Solu%C3%A7%C3%B5es%20em%20Contabilidade/454619944578543/')
    }

    function linkedin(){
        window.open('https://www.linkedin.com/in/ana-maria-nunes-spoljaric-74268842/')
    }
    
    function whats(){
        window.open('https://api.whatsapp.com/send?phone=5512997166077')
    }
    
    return(
        <div id="Footer" className="Footer">
            <div className="left rod">
                <img src={Logo} alt="Logo" />
            </div>
            <div className="center rod">
                <h2>Nunes</h2>
                <h4>soluções em contabilidade</h4>
                <p><i className="fas fa-phone-volume"></i> (12) 3411-1860</p>
                <p><i className="fas fa-envelope"></i> administracao@nunescontabil.com.br</p>
                <p><i className="fas fa-map-marker-alt"></i> Rua São José,531 – Centro – Taubaté</p>
            </div>
            <div className="right rod">
                <div className="iconesFooter">
                    <IconSocial icon="fab fa-facebook-f" click={face} />
                    <IconSocial icon="fab fa-instagram" click={insta} />
                    <IconSocial icon="fab fa-linkedin-in" click={linkedin} />
                    <IconSocial icon="fab fa-whatsapp" click={whats} />
                </div>
            </div>
        </div>
    )
}

export default Footer