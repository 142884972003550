import React from 'react'

const Home = (props) => {

    return(
        <section id="Home" className="Home">
            <div className="img"></div>
            <div className="conteudo">
                <div className="texto">
                    <h1>NUNES CONTABILIDADE</h1>
                    <h3>Especializada em Incorporações e Construção Civil.</h3>
                </div>
                <div className="info">
                    <div className="tel">
                        <span className="txt">
                            Mais informações, ligue-nos!<br />
                            <span className="txt2">(12) 3411-1860</span>
                        </span>
                        <div className="iconeTel"></div>
                    </div>
                    <div className="tel tel2">
                        <span className="txt">
                            Rua São José, 531<br />
                            Centro – Taubaté
                        </span>
                        <div className="iconeLoc"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home