import React from 'react'
import './Endo.css'

const Ritual = (props) => {

    return(
        <div id="Ritual" className="Ritual">
            <div className="conteudo">
                <h1>Ritual da manhã</h1>
                <p>Na Nunes possuímos o nosso ritual da manhã que acontece todas as manhãs, no qual convidamos toda a nossa equipe a esquecer do mundo a fora, deixar os problemas de lado, respirar fundo e mentalizar um ótimo dia de trabalho.</p>
                <p>Para o nosso ritual utilizamos o recurso de áudio para energizar o ambiente, com isso nossos colaborares se soltam, renovam suas energias, e temos o nosso time super preparado pra lhe atender.</p>
            </div>
        </div>
    )
}

export default Ritual