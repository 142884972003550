import React from 'react'
import Toggle from '../../components/Toggle'

const SVC = (props) => {

    return(
        <div id="SVC" className="SVC">
            <h1>Nossos Serviços</h1>
            <div className="servicoCards">
                <div className="servico s1">
                    <Toggle icone="fas fa-gavel" titulo="Escrituração Contábil / Tributária">
                        <p>Uma das maiores obrigações que a contabilidade tem é de cuidar da escrituração contábil. Isso significa ser responsável pelos livros fiscais da empresa, registrando movimentações financeiras, preenchendo demonstrivos e outros documentos importantes e fazendo controles essenciais.</p>
                    </Toggle>
                </div>
                <div className="servico">
                    <Toggle icone="fas fa-hand-holding-usd" titulo="Acessoria para Planejamento Tributário">
                        <p>Esse serviço contábil ajuda a empresa a economizar ao estudar os detalhes da legislação tributária, reduzindo a carga de impostos incidente e ainda cumprindo as normas corretamente.</p>
                    </Toggle>
                </div>
              
                <div className="servico s3">
                    <Toggle icone="far fa-credit-card" titulo="Acessoria para Gestão Financeira">
                        <p>Para contribuir para a gestão financeira, oferecendo dados que irão amparar a tomada de decisão e analisando o desempenho da área. Trazendo informações para os administradores, que passaram despercebidas sem sua participação</p>
                    </Toggle>
                </div>
                <div className="servico s4">
                    <Toggle icone="fas fa-users" titulo="Consultoria Financeira Empresarial">
                        <p>Se houver problemas financeiros, há oportunidades para oferecer soluções contábeis.</p>
                    </Toggle>
                </div>
                <div className="servico">
                    <Toggle icone="fas fa-chart-line" titulo="Controle Patrimonial">
                        <p>Auxiliamos você a administrar seus bens imóveis.</p>
                    </Toggle>
                </div>
                <div className="servico s6">
                    <Toggle icone="fas fa-tasks" titulo="Dentre outros">
                        
                    </Toggle>
                </div>
            </div>
        </div>
    )
}

export default SVC