import React from 'react'
import './Equipe.css'

const Equipe = (props) => {

    return(
        <section id="Equipe" className="Equipe">
            <div className="conteudo">
                <h1>Nossa Equipe</h1>
                <p>Contamos com profissionais qualificados, todos com ampla experiência fiscal, contábil, financeira, societária e compliance.</p>
                <a href="#Equipes" className="vermais"><i className="fas fa-chevron-down"></i> Ver Mais</a>
            </div>
            <div className="imgEquipe">

            </div>
        </section>
    )
}

export default Equipe